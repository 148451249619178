import { Table, useNotifications } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDownloadFile } from '../../../hooks/files/useDownloadFile';
import { useGetMonthlyReports } from '../../../hooks/taxes/monthly-reports/useGetMonthlyReports';
import { PaymentType } from '../../../utils/enums/payment-type.enum';
import { ActionMenuHorizontal } from '../../shared/components/ActionMenuHorizontal';
import { Pagination } from '../../shared/components/Pagination';
import { TableSkeleton } from '../../shared/components/skeletons/TableSkeleton';

export function MonthlyReports() {
  const { t } = useTranslation();
  const notification = useNotifications();
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type') ?? '';

  useEffect(() => {
    if (!type) {
      setSearchParams((prevParams) => ({
        ...prevParams,
        type: PaymentType.Transport.toString(),
        page: 1,
      }));
    }
  }, [setSearchParams, type]);

  const handleReportTypeChange = (
    type: string | number | (string | number)[] | null
  ) => {
    setSearchParams((prevParams) => ({ ...prevParams, type, page: 1 }));
  };

  const {
    data: reports,
    isPending,
    isSuccess,
    isError,
  } = useGetMonthlyReports(+type || 1);

  const downloadFile = useDownloadFile();

  const onDownloadFile = useCallback(
    (fileId: string) => {
      downloadFile
        .mutateAsync(fileId)
        .then((res) => window.open(res.uri))
        .catch((resError) => {
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        });
    },
    [downloadFile, notification, t]
  );

  const headers = [
    t('TAXES.TABLE.HEADERS.FILE_NAME'),
    t('TAXES.TABLE.HEADERS.GENERATED_ON'),
    t('TAXES.TABLE.HEADERS.REPORT_PERIOD'),
    t('USERS.TABLE.HEADERS.ACTION'),
  ];

  const tableChildren = useMemo(() => {
    return reports?.result.reduce((acc: any, report) => {
      let reportsArray = [
        report.fileName,
        report.generatedAt
          ? format(new Date(report.generatedAt), 'dd.MM.yyyy')
          : '',
        report.year && report.month
          ? `${t(
              `MONTHS.${format(
                new Date(report.year, report.month - 1, 1),
                'MMMM'
              ).toUpperCase()}`
            )} ${report.year}`
          : '',

        <ActionMenuHorizontal
          options={[
            {
              label: t('ACTIONS.DOWNLOAD'),
              onClick: () => onDownloadFile(report.fileId),
            },
          ]}
        />,
      ];

      return [...acc, reportsArray];
    }, []);
  }, [onDownloadFile, reports?.result, t]);

  return (
    <div className="taxes__school">
      <div className="taxes__school__filter">
        <button
          type="button"
          className={`taxes__school__filter__item ${
            +type === PaymentType.Transport &&
            ' taxes__school__filter__item-active'
          }`}
          onClick={() => handleReportTypeChange(PaymentType.Transport)}
        >
          {t('TAXES.FILTERS.TRANSPORTATION')}
        </button>
        <button
          type="button"
          className={`taxes__school__filter__item ${
            +type === PaymentType.SchoolFee &&
            ' taxes__school__filter__item-active'
          }`}
          onClick={() => handleReportTypeChange(PaymentType.SchoolFee)}
        >
          {t('TAXES.FILTERS.SCHOOL_TAXES')}
        </button>
      </div>
      <div className="taxes__school__content">
        {isSuccess && reports && (
          <>
            <Table rows={tableChildren} headers={headers} />
            <div className="users__pagination">
              <Pagination data={reports} />
            </div>
          </>
        )}
        {isError && t('TAXES.MESSAGES.NO_MONTHLY_REPORTS')}
        {isPending && <TableSkeleton />}
      </div>
    </div>
  );
}
