import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState, type MouseEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useGetFoodPaymentPricePlan } from '../../hooks/payments/useGetFoodPaymentPricePlan';
import { useSendFoodParameters } from '../../hooks/payments/useSendFoodParameters';
import { useGetMainParentProfile } from '../../hooks/profile/parent/useGetMainParentProfile';
import type { FoodPricePlanDTO } from '../../models/food/payments/food-price-plan.dto';
import type { BoricaParametersDto } from '../../models/payments/borica-parameters.dto';
import type { FoodParametersDto } from '../../models/payments/food-parameters.dto';
import { MONTH_NAMES } from '../../utils/constants/dateContstants';
import { DEFAULT_PAYMENT_FORM_STATE } from '../../utils/constants/payments/default-payment-form-state';
import { MealType } from '../../utils/enums/meal-type.enum';
import { BoricaResponseForm } from './BoricaResponseForm';

export function FoodPayment() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [formState, setFormState] = useState<BoricaParametersDto>(
    DEFAULT_PAYMENT_FORM_STATE
  );
  const [searchParams] = useSearchParams();
  const studentId = searchParams.get('studentId') ?? '';
  const monthParam = searchParams.get('month') ?? '';
  const yearParam = searchParams.get('year') ?? '';
  const withFallback = searchParams.get('withFallback') ?? '';

  const { data: foodPaymentPricePlan, isLoading } = useGetFoodPaymentPricePlan(
    monthParam,
    yearParam
  );
  const { getMainParent } = useGetMainParentProfile();

  const methods = useForm<FoodParametersDto>({
    defaultValues: {
      studentId: '',
      mealMenuId: '',
    },
  });

  const sendFoodParameters = useSendFoodParameters();
  const isSendFoodParametersPending =
    sendFoodParameters.state?.[0]?.status === 'pending';

  const onNavigateBack = () => {
    if (Boolean(withFallback)) {
      navigate(-1);
    } else {
      navigate('/dashboard/payments');
    }
  };

  useEffect(() => {
    if (formRef.current && formState.saleRequest.AMOUNT) {
      formRef.current?.submit();
    }
  }, [formState]);

  const onPayNow = async (data: FoodParametersDto) => {
    sendFoodParameters.mutation
      .mutateAsync(data)
      .then((res) => {
        setFormState(res);
      })
      .catch((error) =>
        notification.danger(t(`NETWORK_ERRORS.${error.errors[0]}`))
      );
  };

  const handlePayPerStudent = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const payButton = event.target as HTMLButtonElement;

    methods.handleSubmit(() => {
      onPayNow({
        mealMenuId: foodPaymentPricePlan?.mealMenuId!,
        studentId: payButton.id,
      });
    })();
  };

  const renderStudents = () => {
    let studentsPlans = foodPaymentPricePlan?.studentPlans;

    if (
      studentId &&
      monthParam &&
      yearParam &&
      foodPaymentPricePlan?.studentPlans
    ) {
      queryClient.invalidateQueries({ queryKey: ['student-meal-menu'] });
      studentsPlans = foodPaymentPricePlan?.studentPlans.filter(
        (studentPlan) => studentPlan.student.id === studentId
      );
    }

    if (!studentsPlans?.length && !isLoading) {
      return <h3>{t('PAYMENTS.TITLES.NO_UNPAID_PLANS')}</h3>;
    }

    return (
      studentsPlans &&
      studentsPlans.map((paymentPlan: FoodPricePlanDTO, index: number) => {
        const sortedWeeks = paymentPlan.weeks.sort((a, b) => a.index - b.index);
        return (
          <div key={index}>
            <div className="payments__wrapper__flex">
              <div className="payments__wrapper">
                <p className="payments__pre-payment__label">
                  {t('USERS.TABLE.ROLE.STUDENT')}
                </p>
                <p className="payments__pre-payment__value">
                  {paymentPlan.student.name}
                </p>
              </div>
              <span>{paymentPlan.amount} BGN</span>
            </div>
            <div className="payments__pre-payment__plans">
              <h4 className="payments__pre-payment__plans__title">
                {t('PAYMENTS.TITLES.MEAL_PLANS')}{' '}
                {t(`MONTHS.${MONTH_NAMES[+monthParam]}`)}
              </h4>
              <div className="payments__pre-payment__plans__weeks">
                {sortedWeeks.map((week, index) => (
                  <div
                    className="payments__pre-payment__plans__weeks__item"
                    key={index}
                  >
                    <span className="payments__pre-payment__plans__weeks__item__label">
                      {t('PAYMENTS.LABELS.WEEK')} {week.index}
                    </span>
                    <span className="payments__pre-payment__plans__weeks__item__value">
                      {week.type === MealType.Meat
                        ? t('PAYMENTS.LABELS.MEAT')
                        : t('PAYMENTS.LABELS.VEG')}
                    </span>
                  </div>
                ))}
              </div>
              <div className="payments__pre-payment__plans__pay-button">
                <Button
                  compact
                  type="submit"
                  id={paymentPlan.student.id}
                  onClick={handlePayPerStudent}
                  loading={isSendFoodParametersPending}
                >
                  {t('ACTIONS.PAY_NOW')}
                </Button>
              </div>
            </div>
          </div>
        );
      })
    );
  };

  return (
    <div className="payments__pre-payment">
      {!isLoading && (
        <FormProvider {...methods}>
          <form>
            <h3 className="payments__pre-payment__title">
              {t('PAYMENTS.TITLES.FOOD_ORDER')}
            </h3>
            <div className="payments__wrapper">
              <p className="payments__pre-payment__label">
                {t('USERS.TABLE.ROLE.PARENT')}
              </p>
              <p className="payments__pre-payment__value">
                {getMainParent()?.name}
              </p>
            </div>
            {renderStudents()}
            <div className="payments__pre-payment__actions">
              <Button compact variant="outlined" onClick={onNavigateBack}>
                {t('ACTIONS.BACK')}
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
      <BoricaResponseForm ref={formRef} formState={formState} />
    </div>
  );
}
