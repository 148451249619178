import { useMutation, useMutationState } from '@tanstack/react-query';
import type { BoricaParametersDto } from '../../models/payments/borica-parameters.dto';
import type { TransportParametersDto } from '../../models/payments/transport-parameters.dto';
import apiRequest from '../../services/apiWrapper';
import { TRANSPORT_PAYMENTS } from '../../utils/constants/urlConstants';

async function sendTransportParameters(data: TransportParametersDto) {
  const res = await apiRequest.post<
    TransportParametersDto,
    BoricaParametersDto
  >(`${TRANSPORT_PAYMENTS}/students`, data);

  return res.data;
}

const mutationKey = ['send-transport-parameters'];

export function useSendTransportParameters() {
  const mutation = useMutation({
    mutationKey,
    mutationFn: (data: TransportParametersDto) => sendTransportParameters(data),
  });

  const state = useMutationState({
    filters: { mutationKey },
    select: (mutation) => mutation.state,
  });

  return { mutation, state };
}
