import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDeleteSchoolTaxes } from '../../hooks/taxes/school-taxes/admin/useDeleteSchoolTaxes';
import { useModal } from '../../hooks/useModal';
import { CONFIRM_DELETE_SCHOOL_TAX } from '../../utils/constants/users/modals';
import { Dialog } from '../shared/components/Dialog';

export function ConfirmDeleteSchoolTaxDialog() {
  const { t } = useTranslation();
  const { close: closeModal } = useModal(CONFIRM_DELETE_SCHOOL_TAX);
  const [searchParams] = useSearchParams();
  const users = searchParams.get('userIds');

  const queryClient = useQueryClient();
  const notification = useNotifications();

  const deleteSelectedTaxes = useDeleteSchoolTaxes();

  let mutation = deleteSelectedTaxes;

  const deleteSchoolTaxes = () => {
    if (users) {
      mutation
        .mutateAsync(users)
        .then(() => {
          notification.success(t('TAXES.DIALOGS.MESSAGES.SCHOOL_TAX_DELETED'));
          queryClient.invalidateQueries({ queryKey: [] });
          closeModal();
        })
        .catch((resError) =>
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`))
        );
    }
  };

  const onCloseModal = () => {
    closeModal();
  };

  return (
    <Dialog
      dialogHeader={t('TAXES.DIALOGS.TITLES.CONFIRM_DELETE_SCHOOL_TAX')}
      className="dialog__delete-tax"
      onClose={onCloseModal}
      actionButton={
        <Button onClick={deleteSchoolTaxes}>{t('ACTIONS.CONFIRM')}</Button>
      }
    >
      <p>{t('TAXES.DIALOGS.LABELS.CONFIRM_DELETE_SCHOOL_TAX')}</p>
    </Dialog>
  );
}
