import { Add, TrashCan } from '@carbon/icons-react';
import { Button, Alert, useNotifications } from '@ph-react-ui/core';
import type { SimpleDate } from '@ph-react-ui/core';
import { differenceInYears } from 'date-fns';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../assets/icons/logo-denis-diderot.svg';
import { useSendApplication } from '../../../hooks/admission/useSendApplication';
import type { ApplicationFormDto } from '../../../models/admission/application-create';
import type { ApplicationCreateFormDto } from '../../../models/admission/application-create-form';
import { mapSimpleDateToString } from '../../../services/mapSimpleDateToString';
import { Checkbox } from '../../../utils/components/hoc-components';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';
import { ParentsInformation } from './ParentsInformation';
import { StudentInformation } from './StudentInformation';
import { TermsOfUse } from './TermsOfUse';

export const ApplicationForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const notification = useNotifications();
  const sendApplication = useSendApplication();

  const methods = useForm<ApplicationCreateFormDto>({
    defaultValues: {
      parents: [
        {
          name: '',
          phoneNumber: '',
          workAddress: '',
          email: '',
          parentType: 0,
        },
        {
          name: '',
          phoneNumber: '',
          workAddress: '',
          email: '',
          parentType: 1,
        },
      ],
      students: [
        {
          name: '',
          address: '',
          birthDate: '' as unknown as SimpleDate,
          allergies: '',
          siblings: '',
          medicalCondition: '',
          gpName: '',
          gpPhoneNumber: '',
          egn: '',
          birthplace: '',
          schoolYear: '',
          citizenship: '',
          additionalInformation: Array.from({ length: 6 }, (_, i) => i + 1).map(
            (num) => ({
              question: t(`ADMISSION.APPLICATION.QUESTIONS.Q${num}`) as string,
              answer: '',
            })
          ),
        },
      ],
      hasAgreedToTerms: false,
    },
  });

  const {
    fields: students,
    append,
    remove,
  } = useFieldArray({
    control: methods?.control,
    name: 'students',
  });

  const appendStudent = () =>
    append({
      id: '',
      name: '',
      address: '',
      birthDate: '' as unknown as SimpleDate,
      medicalCondition: '',
      allergies: '',
      siblings: '',
      egn: '',
      gpName: '',
      gpPhoneNumber: '',
      citizenship: '',
      schoolYear: '',
      birthplace: '',
      additionalInformation: Array.from({ length: 6 }, (_, i) => i + 1).map(
        (num) => ({
          question: t(`ADMISSION.APPLICATION.QUESTIONS.Q${num}`) as string,
          answer: '',
        })
      ),
    });

  const studentsBirthLimit = methods
    .watch('students')
    .some((student) =>
      student.birthDate
        ? differenceInYears(
            new Date(),
            new Date(
              student.birthDate?.year,
              student.birthDate?.month,
              student.birthDate?.date
            )
          ) < 6
        : false
    );

  const onSubmit = async (data: ApplicationCreateFormDto) => {
    if (data.parents[0].email === data.parents[1].email) {
      methods.setError('parents.1.email', {
        message: t('ERROR.VALIDATION.EMAIL_NOT_UNIQUE') || '',
      });
      return;
    }
    const formData: ApplicationFormDto = {
      ...data,
      students: [
        ...data.students.map((student) => ({
          ...student,
          birthDate: mapSimpleDateToString(student.birthDate),
          notes: '',
        })),
      ],
    };
    const filteredObj = Object.fromEntries(
      Object.entries(formData).filter(([, value]) => value !== '')
    ) as ApplicationFormDto;
    const results = {
      ...filteredObj,
      students: filteredObj.students.map((student) =>
        Object.fromEntries(
          Object.entries(student).filter(([, value]) => value !== '')
        )
      ),
    } as ApplicationFormDto;
    await sendApplication
      .mutateAsync(results)
      .then(() => {
        notification.success(t('ADMISSION.APPLICATION.SUCCESSFUL_APPLY'));
        setTimeout(() => {
          navigate('/');
        }, 3000);
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <div className="admission">
      <img src={Logo} alt="logo" className="admission__logo" />
      <div className="admission-form">
        <h2 className="mb-3">{t('ADMISSION.APPLICATION.TITLE')}</h2>
        <h2 className="mb-8">{t('ADMISSION.APPLICATION.SUBTITLE')}</h2>
        <FormProvider {...methods}>
          <form
            id="application-form"
            onSubmit={methods.handleSubmit((data) => onSubmit(data))}
          >
            <h3 className="mb-3">{t('ADMISSION.APPLICATION.STUDENT_INFO')}</h3>
            {students.map((item, index) => {
              return (
                <div className="drawer__users__form__students__new" key={index}>
                  {index !== 0 && (
                    <Button
                      className="drawer__users__form__students__new__trash"
                      variant="link"
                      color="danger"
                      onClick={() => remove(index)}
                    >
                      <TrashCan />
                    </Button>
                  )}
                  <div
                    key={item.id}
                    className="drawer__users__form__students__item"
                  >
                    <StudentInformation formGroupName={`students.${index}.`} />
                  </div>
                </div>
              );
            })}
            <Button
              typeof="button"
              className="mb-8"
              compact
              onClick={appendStudent}
            >
              <Add /> {t('USERS.DRAWER.ACTIONS.ADD_A_STUDENT')}
            </Button>
            <h3>{t('ADMISSION.APPLICATION.PARENT_INFO')}</h3>
            <ParentsInformation />
            <Checkbox
              name="hasAgreedToTerms"
              className="mt-10"
              rules={getCommonValidators([V.REQUIRED])}
            >
              {t('ADMISSION.APPLICATION.TERMS_OF_USE.AGREE')}
            </Checkbox>
            <TermsOfUse />
            {studentsBirthLimit && (
              <Alert type="warning">
                {t('ADMISSION.APPLICATION.WARNING_BIRTHDATE')}
              </Alert>
            )}
            <Button type="submit">{t('ACTIONS.CONTINUE')}</Button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
