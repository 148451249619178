import { Button, type SelectOption } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetInstallmentsByStudent } from '../../../../hooks/taxes/school-taxes/parent/history/useGetInstallmentsByStudent';
import { useModal } from '../../../../hooks/useModal';
import { Select } from '../../../../utils/components/hoc-components';
import { PARTIAL_PAYMENT } from '../../../../utils/constants/taxes/modals';
import { Dialog } from '../../../shared/components/Dialog';

export const PartialPaymentModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const name = searchParams.get('name');

  const { close: closeModal } = useModal(PARTIAL_PAYMENT);
  const { data: installments } = useGetInstallmentsByStudent(id ?? '');
  const [options, setOptions] = useState<SelectOption<string>[]>([]);

  const methods = useForm({
    defaultValues: {
      selectedInstallments: [],
    },
  });

  useEffect(() => {
    if (!installments) {
      return;
    }
    const options = installments?.result
      .filter((item) => !item.isPaid)
      .sort(
        (a, b) =>
          new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime()
      )
      .map((item, index) => ({
        label: `${format(new Date(item.fromDate), 'dd.MM.yyyy')} - ${format(
          new Date(item.toDate),
          'dd.MM.yyyy'
        )} (${item.amount} ${t('TAXES.LABELS.BGN')})`,
        value: item.id,
        disabled: index !== 0,
      }));
    setOptions(options);
  }, [installments, t]);

  const onContinuePayment = () => {
    navigate(
      `/dashboard/payments/school-taxes/pre-payment/${selectedInstallments.join(
        '&'
      )}/${id}`
    );
  };

  const onSubmit = () => {
    onContinuePayment();
  };

  const selectedInstallments = methods.watch('selectedInstallments');

  useEffect(() => {
    setOptions((options) =>
      options.map((item, index) => {
        return {
          ...item,
          disabled: !(
            selectedInstallments.length === index ||
            selectedInstallments.length === index + 1
          ),
        };
      })
    );
  }, [selectedInstallments.length]);

  return (
    <Dialog
      onClose={() => closeModal()}
      dialogHeader={t('PAYMENTS.LABELS.SELECT_PERIOD') ?? ''}
      actionButton={
        <Button
          disabled={!selectedInstallments || !installments?.result.length}
          type="submit"
          form="payment-modal-form"
        >
          {t('ACTIONS.CONTINUE')}
        </Button>
      }
    >
      <FormProvider {...methods}>
        <form id="payment-modal-form" onSubmit={methods.handleSubmit(onSubmit)}>
          <h3 className="mb-3">
            {t('PAYMENTS.DIALOGS.LABELS.GOING_TO_PAY')}
            <span className="dialog__confirm__content__bold">{name}</span>
          </h3>
          <h3 className="mb-7">
            {t('PAYMENTS.DIALOGS.LABELS.CHOOSE_NUM_MONTHS')}
          </h3>
          <Select
            placeholder={t('PAYMENTS.LABELS.PERIOD') ?? ''}
            multi={true}
            options={options}
            name="selectedInstallments"
          />
        </form>
      </FormProvider>
    </Dialog>
  );
};
