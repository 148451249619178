export const CREATE_EDIT_USER = 'create-edit-user';
export const ARCHIVE_USER = 'archive-user';
export const ARCHIVE_USERS = 'archive-users';
export const UNARCHIVE_USER = 'unarchive-user';
export const ADD_TO_GROUP = 'add-to-group';
export const REMOVE_GROUP_MEMBER = 'remove-group-member';
export const ADD_GROUP_USER = 'add-group-user';
export const REGISTER_AN_ABSENCE = 'register-an-absence';
export const CONFIRM_BANK_PAYMENT = 'confirm-bank-payment';
export const CONFIRM_PAYMENT_CANCELLATION = 'confirm-payment-cancellation';
export const CONFIRM_DELETE_SCHOOL_TAX = 'confirm-delete-school-tax';
