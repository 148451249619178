import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../services/apiWrapper';
import {
  SCHOOL_TAX_PAYMENTS,
  TRANSPORT_PAYMENTS,
} from '../../utils/constants/urlConstants';
import { PaymentType } from '../../utils/enums/payment-type.enum';

type PaymentInfo = {
  id: string;
  type: PaymentType;
};

async function generatePdfReceipt(data: PaymentInfo) {
  const res = await apiRequest.post(
    `${
      data.type === PaymentType.Transport
        ? TRANSPORT_PAYMENTS
        : SCHOOL_TAX_PAYMENTS
    }/${data.id}/file-id`,
    {}
  );

  return res.data;
}

export function useGeneratePdfReceipt() {
  return useMutation({
    mutationFn: (data: PaymentInfo) => generatePdfReceipt(data),
  });
}
