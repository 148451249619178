import { useTranslation } from 'react-i18next';
import { mapDateToSimpleDate } from '../../../../../services/mapDateToSimpleDate';
import {
  DatePicker,
  Input,
} from '../../../../../utils/components/hoc-components';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../../../utils/inputValidators';

interface StudentSectionProps {
  formGroupName?: string;
  requiredFields?: {
    [key: string]: boolean;
  };
}

export function StudentSection({
  formGroupName = '',
  requiredFields,
}: StudentSectionProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="mb-6">
        <Input
          name={`${formGroupName}name`}
          rules={getCommonValidators([V.MAX_LENGTH, V.REQUIRED])}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.PUPIL_NAME') ?? ''}
          label={t('USERS.DRAWER.LABELS.PUPIL_NAME')!}
        />
      </div>

      <div className="mb-6">
        <Input
          name={`${formGroupName}egn`}
          rules={
            (getCommonValidators([V.NUMBER_PATTERN, V.EGN_MAX, V.EGN_MIN]),
            {
              required: {
                value: requiredFields?.['egn'] ?? false,
                message: t('ERROR.VALIDATION.REQUIRED'),
              },
            })
          }
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.PUPIL_EGN') ?? ''}
          label={t('USERS.DRAWER.LABELS.PUPIL_EGN') ?? ''}
        />
      </div>
      <div className="mb-6">
        <DatePicker
          name={`${formGroupName}birthDate`}
          rules={{
            required: {
              value: requiredFields?.['birthDate'] ?? false,
              message: t('ERROR.VALIDATION.REQUIRED'),
            },
          }}
          maxDate={mapDateToSimpleDate(new Date())}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.PUPIL_BIRTH_DATE') ?? ''}
          label={t('USERS.DRAWER.LABELS.PUPIL_BIRTH_DATE')!}
        />
      </div>

      <div className="mb-6">
        <Input
          name={`${formGroupName}birthplace`}
          rules={
            (getCommonValidators([V.MAX_LENGTH]),
            {
              required: {
                value: requiredFields?.['birthplace'] ?? false,
                message: t('ERROR.VALIDATION.REQUIRED'),
              },
            })
          }
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.PUPIL_BIRTHPLACE') ?? ''}
          label={t('USERS.DRAWER.LABELS.PUPIL_BIRTHPLACE') ?? ''}
        />
      </div>

      <div className="mb-6">
        <Input
          name={`${formGroupName}schoolYear`}
          rules={
            (getCommonValidators([V.MAX_LENGTH]),
            {
              required: {
                value: requiredFields?.['schoolYear'] ?? false,
                message: t('ERROR.VALIDATION.REQUIRED'),
              },
            })
          }
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.PUPIL_SCHOOL_YEAR') ?? ''}
          label={t('USERS.DRAWER.LABELS.PUPIL_SCHOOL_YEAR') ?? ''}
        />
      </div>

      <div className="mb-6">
        <Input
          name={`${formGroupName}siblings`}
          rules={
            (getCommonValidators([V.MAX_LENGTH]),
            {
              required: {
                value: requiredFields?.['siblings'] ?? false,
                message: t('ERROR.VALIDATION.REQUIRED'),
              },
            })
          }
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.PUPIL_SIBLINGS') ?? ''}
          label={t('USERS.DRAWER.LABELS.PUPIL_SIBLINGS') ?? ''}
        />
      </div>

      <div className="mb-6">
        <Input
          name={`${formGroupName}citizenship`}
          rules={
            (getCommonValidators([V.MAX_LENGTH]),
            {
              required: {
                value: requiredFields?.['citizenship'] ?? false,
                message: t('ERROR.VALIDATION.REQUIRED'),
              },
            })
          }
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.CITIZENSHIP') ?? ''}
          label={t('USERS.DRAWER.LABELS.CITIZENSHIP') ?? ''}
        />
      </div>

      <div className="mb-6">
        <Input
          name={`${formGroupName}address`}
          rules={
            (getCommonValidators([V.MAX_LENGTH]),
            {
              required: {
                value: requiredFields?.['address'] ?? false,
                message: t('ERROR.VALIDATION.REQUIRED'),
              },
            })
          }
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.PUPIL_ADDRESS') ?? ''}
          label={t('USERS.DRAWER.LABELS.PUPIL_ADDRESS') ?? ''}
        />
      </div>
      <div className="mb-6">
        <Input
          name={`${formGroupName}gpName`}
          rules={
            (getCommonValidators([V.MAX_LENGTH]),
            {
              required: {
                value: requiredFields?.['gpName'] ?? false,
                message: t('ERROR.VALIDATION.REQUIRED'),
              },
            })
          }
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.GP_NAME') ?? ''}
          label={t('USERS.DRAWER.LABELS.GP_NAME') ?? ''}
        />
      </div>
      <div className="mb-6">
        <Input
          name={`${formGroupName}gpPhoneNumber`}
          rules={{
            maxLength: {
              value: 20,
              message: t('USERS.DRAWER.ERRORS.PHONE_LENGTH_ERROR'),
            },
          }}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.GP_PHONE_NUMBER') ?? ''}
          label={t('USERS.DRAWER.LABELS.GP_PHONE_NUMBER') ?? ''}
        />
      </div>
    </>
  );
}
