import { useQuery } from '@tanstack/react-query';
import type { SchoolTaxesDto } from '../../../models/taxes/school/school-taxes-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { SCHOOL_TAX_PAYMENTS } from '../../../utils/constants/urlConstants';

async function fetchSchoolTaxes() {
  const response = await apiRequest.get<SchoolTaxesDto>(
    `${SCHOOL_TAX_PAYMENTS}/students/price-plan`
  );

  return response.data.pricePlans;
}

export function useGetSchoolTaxes() {
  return useQuery({
    queryKey: ['school-taxes'],
    queryFn: () => fetchSchoolTaxes(),
    throwOnError: false,
  });
}
