import { Button, Checkbox, Table, Tag } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMatch, useSearchParams } from 'react-router-dom';
import {
  createQueryKey,
  usePaginatedPromisedSchoolTaxes,
} from '../../../../hooks/taxes/school-taxes/admin/usePaginatedPromisedSchoolTaxes';
import { useModal } from '../../../../hooks/useModal';
import { useSelectAll } from '../../../../hooks/useSelectAll';
import type { TaxUser } from '../../../../models/taxes/tax-user';
import {
  Scholarship,
  type ScholarshipType,
} from '../../../../models/users/students/scholarship-type';
import {
  CONFIRM_BANK_PAYMENT,
  CONFIRM_DELETE_SCHOOL_TAX,
} from '../../../../utils/constants/users/modals';
import { TaxesOrdering } from '../../../../utils/enums/taxes-ordering.enum';
import { ActionMenuHorizontal } from '../../../shared/components/ActionMenuHorizontal';
import { Pagination } from '../../../shared/components/Pagination';
import { TableSkeleton } from '../../../shared/components/skeletons/TableSkeleton';
import { ThSortItem } from '../../../shared/components/tables/ThSortItem';
import { TaxesFilters } from '../../../shared/components/TaxesFilters';

export function AdminSchoolTaxesReview() {
  const match = useMatch('/dashboard/taxes/school-taxes');
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const { open: openConfirmBankPaymentModal } = useModal(CONFIRM_BANK_PAYMENT);
  const { open: openConfirmDeleteModal } = useModal(CONFIRM_DELETE_SCHOOL_TAX);

  const {
    data: adminSchoolTaxes,
    isSuccess,
    isPending,
    isError,
  } = usePaginatedPromisedSchoolTaxes(searchParams);

  const { control, handleSelectAll, selectAll, dirtyFields, watch } =
    useSelectAll(adminSchoolTaxes?.result, 'isPaid');

  const users = watch('items');

  useEffect(() => {
    if (adminSchoolTaxes) {
      queryClient.setQueryData(
        ['promised-school-taxes', createQueryKey(searchParams)],
        adminSchoolTaxes
      );
    }
  }, [adminSchoolTaxes, searchParams, queryClient]);

  const renderHeader = (users: TaxUser[], dirtyFields: any) => {
    const onOpenMany = (isMarkAsPaid: boolean) => {
      const ids = users
        .filter((user) => user.value)
        .map((user) => user.id)
        .join('&');
      isMarkAsPaid
        ? openConfirmBankPaymentModal({
            userIds: ids,
          })
        : openConfirmDeleteModal({ userIds: ids });
    };
    return (
      <>
        <Button
          disabled={!dirtyFields.items?.some((user: TaxUser) => user.value)}
          className="taxes__transportation__filter__bulk"
          onClick={() => onOpenMany(true)}
        >
          {t('ACTIONS.MARK_AS_PAID')}
        </Button>
        <Button
          disabled={!dirtyFields.items?.some((user: TaxUser) => user.value)}
          className="taxes__transportation__filter__bulk"
          onClick={() => onOpenMany(false)}
        >
          {t('ACTIONS.DELETE')}
        </Button>
      </>
    );
  };

  const onMarkAsPaid = useCallback(
    (ids: string[]) => {
      const selectedIds = ids.join('&');
      openConfirmBankPaymentModal({
        userIds: selectedIds,
      });
    },
    [openConfirmBankPaymentModal]
  );

  const onDelete = useCallback(
    (ids: string[]) => {
      const selectedIds = ids.join('&');
      openConfirmDeleteModal({
        userIds: selectedIds,
      });
    },
    [openConfirmDeleteModal]
  );

  const headers = [
    <div className="users__table__select-all">
      <Checkbox
        onChange={(checked) => handleSelectAll(checked)}
        value={selectAll}
      />
      {t('TAXES.TABLE.HEADERS.PAYMENT_ID')}
    </div>,
    t('TAXES.TABLE.HEADERS.ORDER_ID'),
    <ThSortItem
      title={t('USERS.TABLE.HEADERS.NAME')}
      ordering={TaxesOrdering.Name}
    />,
    t('TAXES.TABLE.HEADERS.TYPE'),
    t('TAXES.TABLE.HEADERS.GENERATED_ON'),
    t('TAXES.TABLE.HEADERS.PAYMENT_PERIOD'),
    t('TAXES.TABLE.HEADERS.PAID_BY'),
    <ThSortItem
      title={t('TAXES.TABLE.HEADERS.PAID_AT')}
      ordering={TaxesOrdering.PaidAt}
    ></ThSortItem>,
    t('TAXES.TABLE.HEADERS.AMOUNT'),
    t('TAXES.TABLE.HEADERS.STATUS'),
    t('TAXES.TABLE.HEADERS.PAID_WITH'),
    t('USERS.TABLE.HEADERS.ACTION'),
  ];

  const isOnSchoolTaxesScholarship = (scholarshipType: ScholarshipType) =>
    scholarshipType !== Scholarship.NO_SCHOLARSHIP;

  const tableChildren = useMemo(() => {
    return adminSchoolTaxes?.result.reduce((acc: any, user, index) => {
      let userArray = [
        <div className="users__table__checkbox">
          <Controller
            name={`items.${index}.value`}
            control={control}
            disabled={user.isPaid}
            render={({ field }) => (
              <Checkbox
                className="users__table-first"
                {...field}
                value={user.isPaid ? false : field.value}
              />
            )}
          />
          {user.code}
        </div>,
        user.order || '',
        user.name,
        isOnSchoolTaxesScholarship(user.scholarshipType)
          ? t('TAXES.LABELS.SCHOLARSHIP')
          : t('TAXES.LABELS.REGULAR'),
        user.generatedAt
          ? format(new Date(user.generatedAt), 'dd.MM.yyyy')
          : '',
        user.fromDate
          ? `${t(
              `MONTHS.${format(new Date(user.fromDate), 'MMMM').toUpperCase()}`
            )} ${new Date(user.fromDate).getFullYear()}`
          : '-',
        user.isPaid ? user.paidByName : '',
        user.isPaid && user.paidAt
          ? format(new Date(user.paidAt), 'dd.MM.yyyy')
          : '',
        <span
          className={`${
            !user.isPaid ? 'taxes__transportation__table__row-danger' : ''
          }`}
        >
          {user.amount.toFixed(2)} &nbsp; {t('PAYMENTS.LABELS.BGN')}
        </span>,
        user.isPaid ? (
          <Tag type="success">{t('TAXES.LABELS.PAID')}</Tag>
        ) : (
          <Tag type="danger">{t('TAXES.LABELS.NOT_PAID')}</Tag>
        ),
        user.isPaid
          ? user.isPaidWithCard
            ? t('TAXES.LABELS.CARD')
            : t('TAXES.LABELS.TRANSFER')
          : '',
        !user.isPaid ? (
          <ActionMenuHorizontal
            options={[
              {
                label: t('ACTIONS.SEND_REMINDER'),
                hidden: true,
                onClick: () => {}, //TODO
              },
              {
                label: t('ACTIONS.MARK_AS_PAID'),
                onClick: () => onMarkAsPaid([user.id]),
              },
              {
                label: t('ACTIONS.DELETE'),
                onClick: () => onDelete([user.id]),
              },
            ]}
          />
        ) : (
          ''
        ),
      ];
      return [...acc, userArray];
    }, []);
  }, [adminSchoolTaxes?.result, t, onMarkAsPaid, control, onDelete]);

  return (
    <div className="taxes__school">
      <div className="taxes__school__content">
        {isSuccess && adminSchoolTaxes && (
          <>
            <div className="taxes__school__filter">
              <TaxesFilters pathMatch={match} />
              {renderHeader(users, dirtyFields)}
            </div>
            <Table rows={tableChildren} headers={headers} />
            <div className="users__pagination">
              <Pagination data={adminSchoolTaxes} />
            </div>
          </>
        )}
        {isError && t('TAXES.MESSAGES.NO_TRANSPORTATION_TAXES')}
        {isPending && <TableSkeleton />}
      </div>
    </div>
  );
}
