import { Checkbox, Table, Tag } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useMemo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { type PathMatch, useLocation } from 'react-router-dom';
import { useModal } from '../../../hooks/useModal';
import { useSelectAll } from '../../../hooks/useSelectAll';
import type { PaginatedList } from '../../../models/common/paginated-list.model';
import type { TaxUser } from '../../../models/taxes/tax-user';
import type { TaxesDetailsDto } from '../../../models/taxes/taxes-details.dto';
import { ScholarshipTranslations } from '../../../models/users/students/scholarship-type';
import { CONFIRM_BANK_PAYMENT } from '../../../utils/constants/users/modals';
import { ROLE } from '../../../utils/constants/users/role';
import { TaxesOrdering } from '../../../utils/enums/taxes-ordering.enum';
import { ActionMenuHorizontal } from './ActionMenuHorizontal';
import { Pagination } from './Pagination';
import { ThSortItem } from './tables/ThSortItem';
import { TaxesFilters } from './TaxesFilters';

interface TaxesPaginatedList<T> extends PaginatedList<T> {
  month: number;
  year: number;
  mealMenuId?: string;
}
interface TaxesBaseTableProps {
  data: TaxesPaginatedList<TaxesDetailsDto>;
  renderBulkActions: (
    users: TaxUser[],
    dirtyFields: Partial<Readonly<any>>
  ) => JSX.Element;
  pathMatch: PathMatch<string> | null;
}

export const TaxesBaseTable = ({
  renderBulkActions,
  data,
  pathMatch,
}: TaxesBaseTableProps) => {
  const { t } = useTranslation();
  const { open: openConfirmBankPaymentModal } = useModal(CONFIRM_BANK_PAYMENT);
  const location = useLocation();
  const isFoodTaxes = location.pathname.includes('food');

  const { control, handleSelectAll, selectAll, dirtyFields, watch } =
    useSelectAll(data?.result, 'isPaid');

  const users = watch('items');

  const onSendReminder = (id: string) => {
    id;
  };

  const onMarkAsPaid = useCallback(
    (ids: string[]) => {
      const selectedIds = ids.join('&');
      isFoodTaxes
        ? openConfirmBankPaymentModal({
            userIds: selectedIds,
            mealMenuId: data?.mealMenuId!,
          })
        : openConfirmBankPaymentModal({
            userIds: selectedIds,
          });
    },
    [openConfirmBankPaymentModal, data?.mealMenuId, isFoodTaxes]
  );

  const headers = [
    <div className="users__table__select-all">
      <Checkbox
        onChange={(checked) => handleSelectAll(checked)}
        value={selectAll}
      />
      <ThSortItem
        title={t('USERS.TABLE.HEADERS.NAME')}
        ordering={TaxesOrdering.Name}
      />
    </div>,
    t('TAXES.TABLE.HEADERS.ORDER_ID'),
    t('USERS.TABLE.HEADERS.ROLE'),
    t('TAXES.TABLE.HEADERS.TYPE'),
    t('TAXES.TABLE.HEADERS.PAID_BY'),
    t('TAXES.TABLE.HEADERS.AMOUNT'),
    t('TAXES.TABLE.HEADERS.STATUS'),
    t('TAXES.TABLE.HEADERS.PAID_WITH'),
    <ThSortItem
      title={t('TAXES.TABLE.HEADERS.PAID_AT')}
      ordering={TaxesOrdering.PaidAt}
    ></ThSortItem>,
    t('USERS.TABLE.HEADERS.ACTION'),
  ];

  const tableChildren = useMemo(() => {
    return data?.result.reduce((acc: any, user, index) => {
      let userArray = [
        <div className="users__table__checkbox">
          <Controller
            name={`items.${index}.value`}
            control={control}
            disabled={user.isPaid}
            render={({ field }) => (
              <Checkbox
                className="users__table-first"
                {...field}
                value={user.isPaid ? false : field.value}
              />
            )}
          />
          {user.name}
        </div>,
        user.order || '',
        t(`USERS.TABLE.ROLE.${ROLE[user.role]}`),
        t(`TAXES.LABELS.${ScholarshipTranslations[user.scholarshipType!]}`),
        user.paidByName,
        <span
          className={`${
            !user.isPaid ? 'taxes__transportation__table__row-danger' : ''
          }`}
        >
          {user.amount} &nbsp; {t('PAYMENTS.LABELS.BGN')}
        </span>,
        user.isPaid ? (
          <Tag type="success">{t('TAXES.LABELS.PAID')}</Tag>
        ) : (
          <Tag type="danger">{t('TAXES.LABELS.NOT_PAID')}</Tag>
        ),
        user.isPaid
          ? user.isPaidWithCard
            ? t('TAXES.LABELS.CARD')
            : t('TAXES.LABELS.TRANSFER')
          : '',
        user.paidAt ? format(new Date(user.paidAt), 'dd.MM.yyyy') : '',
        !user.isPaid ? (
          <ActionMenuHorizontal
            options={[
              {
                label: t('ACTIONS.SEND_REMINDER'),
                hidden: true,
                onClick: () => onSendReminder('id'),
              },
              {
                label: t('ACTIONS.MARK_AS_PAID'),
                onClick: () => onMarkAsPaid([user.id]),
              },
            ]}
          />
        ) : (
          ''
        ),
      ];

      return [...acc, userArray];
    }, []);
  }, [control, data?.result, t, onMarkAsPaid]);

  return (
    <>
      <div className="taxes__food__filter">
        <TaxesFilters pathMatch={pathMatch} />
        {renderBulkActions(users, dirtyFields)}
      </div>
      <Table rows={tableChildren} headers={headers} />
      <div className="users__pagination">
        <Pagination data={data} />
      </div>
    </>
  );
};
