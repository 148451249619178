import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../../../../models/common/paginated-list.model';
import type { HistoryOfSchoolTaxesPaymentsDto } from '../../../../../models/taxes/school/history-of-school-taxes-payments.dto';
import apiRequest from '../../../../../services/apiWrapper';
import { SCHOOL_TAX_PAYMENT_HISTORY_URL } from '../../../../../utils/constants/urlConstants';

export async function fetchInstallmentsByStudent(studentId: string) {
  const response = await apiRequest.get<
    PaginatedList<HistoryOfSchoolTaxesPaymentsDto>
  >(`${SCHOOL_TAX_PAYMENT_HISTORY_URL}?size=9999&studentId=${studentId}`);

  return response.data;
}

export function useGetInstallmentsByStudent(studentId: string) {
  return useQuery({
    queryKey: ['installments', studentId],
    queryFn: () => fetchInstallmentsByStudent(studentId),
    enabled: studentId !== '',
  });
}
