import { Button } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetPreviousUnpaidTax } from '../../hooks/taxes/school-taxes/admin/useGetPreviousUnpaidTax';
import { useGetInstallmentsByStudent } from '../../hooks/taxes/school-taxes/parent/history/useGetInstallmentsByStudent';
import { useModal } from '../../hooks/useModal';
import { SELECT_PAYMENT_PERIOD } from '../../utils/constants/taxes/modals';

export const SchoolTaxPayment = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { id, studentId } = useParams();
  const [searchParams] = useSearchParams();
  const withFallback = searchParams.get('withFallback') ?? '';
  const { open: openPaymentPeriodModal } = useModal(SELECT_PAYMENT_PERIOD);

  const { data: installments } = useGetInstallmentsByStudent(studentId ?? '');

  const { data: previousUnpaidTaxes, isPending } = useGetPreviousUnpaidTax(
    id?.split('&') || [],
    location.pathname.includes('school-taxes')
  );

  const hasPreviousUnpaidTaxes = Boolean(previousUnpaidTaxes);

  const selectedInstallments = installments?.result
    .filter((result) => id?.split('&').includes(result.id))
    .sort(
      (a, b) => new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime()
    );

  const installmentsDetails = {
    studentName: installments ? installments.result[0].name : '',
    totalPayment: selectedInstallments?.reduce(
      (acc, curr) => acc + curr.amount,
      0
    ),
    period:
      selectedInstallments &&
      selectedInstallments[0].fromDate &&
      `${format(
        new Date(selectedInstallments[0].fromDate!),
        'dd.MM.yyyy'
      )} - ${format(
        new Date(selectedInstallments[selectedInstallments.length - 1].toDate!),
        'dd.MM.yyyy'
      )}`,
    months: selectedInstallments?.length,
  };

  const onNavigateBack = () => {
    queryClient.invalidateQueries({ queryKey: ['school-taxes'] });
    if (withFallback) {
      navigate('/dashboard/payments/school-taxes/history');
    } else {
      navigate('/dashboard/payments/school-taxes');
    }
  };

  const onOpenPaymentPeriodModal = () => {
    openPaymentPeriodModal();
  };

  return (
    <div className="payments__pre-payment">
      {installments && installmentsDetails && (
        <>
          <h3 className="payments__pre-payment__title">
            {t('PAYMENTS.TITLES.SCHOOL_TAXES')}
          </h3>
          <div className="payments__wrapper">
            <p className="payments__pre-payment__label">
              {t('USERS.TABLE.ROLE.STUDENT')}
            </p>
            <p className="payments__pre-payment__value">
              {installmentsDetails.studentName}
            </p>
          </div>
          <div className="payments__wrapper">
            <p className="payments__pre-payment__label">
              {t('PAYMENTS.LABELS.PERIOD')}
            </p>
            <p className="payments__pre-payment__value">
              {installmentsDetails.months}&nbsp;
              {t(
                `PAYMENTS.LABELS.${
                  +installmentsDetails.months! > 1 ? 'MONTHS' : 'MONTH'
                }`
              )}
              <p className="payments__pre-payment__value">
                {installmentsDetails.period}
              </p>
            </p>
          </div>
          <div className="payments__total">
            {t('PAYMENTS.TITLES.TOTAL')}:
            <p>
              {(+installmentsDetails.totalPayment!).toFixed(2)}{' '}
              {t('PAYMENTS.LABELS.BGN')}
            </p>
            {hasPreviousUnpaidTaxes && (
              <div className="payments__total">
                <p>
                  {t('TAXES.DIALOGS.MESSAGES.UNPAID_TAXES_PAYMENT', {
                    period: `${t(
                      `MONTHS.${format(
                        new Date(previousUnpaidTaxes?.fromDate || ''),
                        'MMMM'
                      ).toUpperCase()}`
                    )} ${new Date(
                      previousUnpaidTaxes?.fromDate || ''
                    ).getFullYear()}`,
                  })}
                </p>
              </div>
            )}
          </div>
          <div className="payments__pre-payment__actions">
            <Button compact variant="outlined" onClick={onNavigateBack}>
              {t('ACTIONS.BACK')}
            </Button>
            <Button
              compact
              onClick={onOpenPaymentPeriodModal}
              disabled={hasPreviousUnpaidTaxes}
              loading={isPending}
            >
              {t('ACTIONS.PAY_NOW')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
