import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../../../services/apiWrapper';
import { SCHOOL_TAX_PAYMENTS } from '../../../../utils/constants/urlConstants';

async function deleteSchoolTaxes(ids: string) {
  const idsQuery = ids.split('&').join('&ids=');
  return apiRequest.delete(`${SCHOOL_TAX_PAYMENTS}?ids=${idsQuery}`, null);
}

export function useDeleteSchoolTaxes() {
  return useMutation({
    mutationFn: (ids: string) => deleteSchoolTaxes(ids),
  });
}
