import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../../models/common/paginated-list.model';
import type { MonthlyReportDto } from '../../../models/taxes/monthly-reports.dto';
import apiRequest from '../../../services/apiWrapper';
import { MONTHLY_REPORTS_URL } from '../../../utils/constants/urlConstants';

async function fetchMonthlyReports(taxType: number) {
  const response = await apiRequest.get<PaginatedList<MonthlyReportDto>>(
    `${MONTHLY_REPORTS_URL}?reportPaymentType=${taxType}`
  );

  return response.data;
}

export function useGetMonthlyReports(taxType: number) {
  return useQuery({
    queryKey: ['monthly-reports', taxType],
    queryFn: () => fetchMonthlyReports(taxType),
    enabled: taxType === 1 || taxType === 2,
  });
}
