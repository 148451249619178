import { Finance, Purchase } from '@carbon/icons-react';
import { Button, useNotifications } from '@ph-react-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSendSchoolParameters } from '../../../../hooks/payments/useSendSchoolTaxParameters';
import { useModal } from '../../../../hooks/useModal';
import type { BoricaParametersDto } from '../../../../models/payments/borica-parameters.dto';
import { DEFAULT_PAYMENT_FORM_STATE } from '../../../../utils/constants/payments/default-payment-form-state';
import {
  SELECT_PAYMENT_PERIOD,
  TRANSFER_DETAILS,
} from '../../../../utils/constants/taxes/modals';
import { PaymentMethod } from '../../../../utils/enums/payment-method.enum';
import { BoricaResponseForm } from '../../../payments/BoricaResponseForm';
import { Dialog } from '../../../shared/components/Dialog';

export const SelectPaymentMethodDialog = () => {
  const { t } = useTranslation();
  const notification = useNotifications();
  const { id } = useParams();
  const { close: closeModal } = useModal(SELECT_PAYMENT_PERIOD);
  const { open: openTransferDetailsModal } = useModal(TRANSFER_DETAILS);
  const [selectedPayment, setSelectedPayment] = useState<PaymentMethod | null>(
    null
  );
  const formRef = useRef<HTMLFormElement | null>(null);
  const [formState, setFormState] = useState<BoricaParametersDto>(
    DEFAULT_PAYMENT_FORM_STATE
  );

  const sendSchoolTaxParameters = useSendSchoolParameters();

  useEffect(() => {
    if (formRef.current && formState.saleRequest.AMOUNT) {
      formRef.current?.submit();
    }
  }, [formState]);

  const onCloseModal = () => {
    closeModal();
  };

  const onContinuePayment = async () => {
    if (selectedPayment === PaymentMethod.BankTransfer)
      openTransferDetailsModal();
    else if (selectedPayment === PaymentMethod.Card) {
      await sendSchoolTaxParameters
        .mutateAsync({
          paymentIds: id?.split('&')!,
        })
        .then((res) => {
          setFormState(res);
        })
        .catch((resError) => {
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        });
    }
  };

  return (
    <>
      <Dialog
        onClose={onCloseModal}
        dialogHeader={t('PAYMENTS.LABELS.SELECT_METHOD')}
        actionButton={
          <Button disabled={!selectedPayment} onClick={onContinuePayment}>
            {t('ACTIONS.CONTINUE')}
          </Button>
        }
      >
        <div className="dialog__confirm__content__payment-wrapper">
          <button
            onClick={() => setSelectedPayment(PaymentMethod.Card)}
            className={selectedPayment === PaymentMethod.Card ? 'active' : ''}
          >
            <Purchase size={24} />
            <h3>{t('TAXES.LABELS.CARD')}</h3>
          </button>
          <button
            onClick={() => setSelectedPayment(PaymentMethod.BankTransfer)}
            className={
              selectedPayment === PaymentMethod.BankTransfer ? 'active' : ''
            }
          >
            <Finance size={24} />
            <h3>{t('TAXES.LABELS.TRANSFER')}</h3>
          </button>
        </div>
      </Dialog>
      <BoricaResponseForm ref={formRef} formState={formState} />
    </>
  );
};
