import { forwardRef } from 'react';
import type { BoricaParametersDto } from '../../models/payments/borica-parameters.dto';

interface BoricaResponseFormProps {
  formState: BoricaParametersDto;
}
export const BoricaResponseForm = forwardRef<
  HTMLFormElement,
  BoricaResponseFormProps
>(({ formState }: BoricaResponseFormProps, ref) => {
  return (
    <form
      // target="_blank"
      action={formState.paymentProviderUrl}
      method="post"
      ref={ref}
    >
      <input
        type="hidden"
        name="TERMINAL"
        value={formState.saleRequest.TERMINAL}
      />
      <input type="hidden" name="TRTYPE" value={formState.saleRequest.TRTYPE} />
      <input type="hidden" name="AMOUNT" value={formState.saleRequest.AMOUNT} />
      <input
        type="hidden"
        name="CURRENCY"
        value={formState.saleRequest.CURRENCY}
      />
      <input type="hidden" name="ORDER" value={formState.saleRequest.ORDER} />
      <input type="hidden" name="DESC" value={formState.saleRequest.DESC} />
      <input
        type="hidden"
        name="MERCHANT"
        value={formState.saleRequest.MERCHANT}
      />
      <input
        type="hidden"
        name="MERCH_NAME"
        value={formState.saleRequest.MERCH_NAME}
      />
      <input
        type="hidden"
        name="MERCH_URL"
        value={formState.saleRequest.MERCH_URL}
      />
      <input type="hidden" name="EMAIL" value={formState.saleRequest.EMAIL} />
      <input
        type="hidden"
        name="COUNTRY"
        value={formState.saleRequest.COUNTRY}
      />
      <input
        type="hidden"
        name="MERCH_GMT"
        value={formState.saleRequest.MERCH_GMT}
      />
      <input type="hidden" name="LANG" value={formState.saleRequest.LANG} />
      <input
        type="hidden"
        name="ADDENDUM"
        value={formState.saleRequest.ADDENDUM}
      />
      <input
        type="hidden"
        name="AD.CUST_BOR_ORDER_ID"
        value={formState.saleRequest['AD.CUST_BOR_ORDER_ID']}
      />
      <input
        type="hidden"
        name="TIMESTAMP"
        value={formState.saleRequest.TIMESTAMP}
      />
      <input type="hidden" name="M_INFO" value={formState.saleRequest.M_INFO} />
      <input type="hidden" name="NONCE" value={formState.saleRequest.NONCE} />
      <input type="hidden" name="P_SIGN" value={formState.saleRequest.P_SIGN} />
      <input type="hidden" name="RFU" value={formState.saleRequest.RFU} />
      <input
        type="hidden"
        name="BACKREF"
        value={formState.saleRequest.BACKREF}
      />
    </form>
  );
});
